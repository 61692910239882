// QuillComponent.jsx
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomToolbar from "./CustomToolbat";

// Custom clipboard module to handle line breaks
const Clipboard = Quill.import("modules/clipboard");
const Delta = Quill.import("delta");

class PlainClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault();
    const range = this.quill.getSelection();
    const text = e.clipboardData.getData("text/plain");
    const cleanText = text.replace(/\n\n/g, "\n");
    const delta = new Delta()
      .retain(range.index)
      .delete(range.length)
      .insert(cleanText);

    const index = cleanText.length + range.index;
    this.quill.updateContents(delta, "silent");
    this.quill.setSelection(index, 0, "silent");
    this.quill.scrollIntoView();
  }
}

Quill.register("modules/clipboard", PlainClipboard, true);

const QuillComponent = ({ value, onChange, loading = false }) => {
  const quillRef = useRef(null);

  return (
    <div>
      <CustomToolbar />
      <ReactQuill
        ref={quillRef}
        className={`font-montserrat h-[60vh] ${loading ? "animateQuill" : ""}`}
        theme="snow"
        value={value}
        onChange={onChange}
        modules={QuillComponent.modules}
        formats={QuillComponent.formats}
        placeholder={
          "Write something legendary 🖋️......." +
          "\n\n" +
          "Tips: " +
          "\n\n" +
          "- You can also use ctrl/cmd + s to save your note." +
          "\n\n" +
          "- Add # to title and store notes on-device (completely private)" +
          "\n\n" +
          "- New Interface & features on the way 🚀"
        }
      />
    </div>
  );
};

QuillComponent.modules = {
  toolbar: {
    container: "#toolbar",
  },
  clipboard: {
    matchVisual: false,
  },
};

QuillComponent.formats = [
  "header",
  "font",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "code-block",
  "align",
  "direction",
  "video",
];

export default QuillComponent;
