// CustomToolbar.jsx
import React from "react";
import ToggleSwitch from "../atoms/LiveToggle";
import { Quill } from "react-quill";

const CustomToolbar = ({ onToggle }) => {
  const handleCopy = async () => {
    try {
      const editor = document.querySelector(".ql-editor");
      if (editor) {
        // Process the text before copying to remove extra line breaks
        const text = editor.innerText;
        const processedText = text.replace(/\n\s*\n/g, "\n").trim();
        await navigator.clipboard.writeText(processedText);
      }
    } catch (error) {
      console.error("Failed to copy text:", error);
    }
  };

  return (
    <div id="toolbar" className="font-montserrat flex flex-wrap items-center">
      <select className="ql-header">
        <option value="1"></option>
        <option value="2"></option>
        <option selected></option>
      </select>
      <select className="ql-font">
        <option value="normal" selected>
          Normal
        </option>
        <option value="sans-serif">Sans Serif</option>
      </select>
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
      <button className="ql-strike"></button>
      <button className="ql-blockquote"></button>
      <button className="ql-code-block"></button>
      <select className="ql-align"></select>
      <button className="ql-list" value="ordered"></button>
      <button className="ql-list" value="bullet"></button>
      <button className="ql-indent" value="-1"></button>
      <button className="ql-indent" value="+1"></button>
      <button className="ql-link"></button>
      <button className="ql-image"></button>
      <button className="ql-video"></button>
      <div className="ml-auto items-center space-x-2 mt-2 md:mt-0 hidden md:flex font-[500]">
        <button
          className="ql-copy relative group"
          onClick={handleCopy}
          style={{ fontSize: "18px" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="w-4 h-4"
          >
            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
          </svg>
          <span className="absolute hidden group-hover:block bg-gray-100 text-white text-sm rounded-sm px-2 py-1 -bottom-8 left-1/2 transform -translate-x-1/2">
            Copy
          </span>
        </button>
      </div>
    </div>
  );
};

export default CustomToolbar;
